import React from 'react';
import './Footer.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import {faShare} from '@fortawesome/free-solid-svg-icons';
import FloatingIcons from './FloatingIcons';

const Footer = () => {
  return (
    <div className="container-fluid footer py-5 wow fadeIn" data-wow-delay="0.2s">
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-md-6 col-lg-6 col-xl-3">
          <div className="footer-item d-flex flex-column">
            <h4 className="text-white mb-4">
             
              THE PHYSIORA
            </h4>
            <p style={{textAlign: "justify"}}>
            Welcome to The Physiora, where compassionate care meets tailored therapies. Discover effective physiotherapy solutions designed to enhance your well-being.
            </p>
            <svg className="svg--source" width={0} height={0} aria-hidden="true">
    <symbol id="svg--twitter" viewBox="0 -7 15 30">
      <path d="M15.36 3.434c-0.542 0.241-1.124 0.402-1.735 0.476 0.624-0.374 1.103-0.966 1.328-1.67-0.583 0.346-1.23 0.598-1.917 0.733-0.551-0.587-1.336-0.954-2.205-0.954-1.668 0-3.020 1.352-3.020 3.019 0 0.237 0.026 0.467 0.078 0.688-2.51-0.126-4.735-1.328-6.224-3.155-0.261 0.446-0.41 0.965-0.41 1.518 0 1.048 0.534 1.972 1.344 2.514-0.495-0.016-0.961-0.151-1.368-0.378 0 0.013 0 0.025 0 0.038 0 1.463 1.042 2.683 2.422 2.962-0.253 0.069-0.52 0.106-0.796 0.106-0.194 0-0.383-0.018-0.568-0.054 0.384 1.2 1.5 2.073 2.821 2.097-1.034 0.81-2.335 1.293-3.75 1.293-0.244 0-0.484-0.014-0.72-0.042 1.336 0.857 2.923 1.357 4.63 1.357 5.554 0 8.592-4.602 8.592-8.593 0-0.13-0.002-0.261-0.009-0.39 0.59-0.426 1.102-0.958 1.507-1.563z" />
    </symbol>
    <symbol id="svg--google" viewBox="-13 -13 72 72">
      <path
        d="M48,22h-5v-5h-4v5h-5v4h5v5h4v-5h5 M16,21v6.24h8.72c-0.67,3.76-3.93,6.5-8.72,6.5c-5.28,0-9.57-4.47-9.57-9.75
s4.29-9.74,9.57-9.74c2.38,0,4.51,0.82,6.19,2.42v0.01l4.51-4.51C23.93,9.59,20.32,8,16,8C7.16,8,0,15.16,0,24s7.16,16,16,16
c9.24,0,15.36-6.5,15.36-15.64c0-1.17-0.11-2.29-0.31-3.36C31.05,21,16,21,16,21z"
      />
    </symbol>
    <symbol id="svg--facebook" viewBox="0 -7 16 30">
      <path d="M12 3.303h-2.285c-0.27 0-0.572 0.355-0.572 0.831v1.65h2.857v2.352h-2.857v7.064h-2.698v-7.063h-2.446v-2.353h2.446v-1.384c0-1.985 1.378-3.6 3.269-3.6h2.286v2.503z" />
    </symbol>
    <symbol id="svg--github" viewBox="-30 -30 150 150">
      <path
        d="M61.896,52.548c-3.59,0-6.502,4.026-6.502,8.996c0,4.971,2.912,8.999,6.502,8.999
		c3.588,0,6.498-4.028,6.498-8.999C68.395,56.574,65.484,52.548,61.896,52.548z M84.527,29.132c0.74-1.826,0.777-12.201-3.17-22.132
		c0,0-9.057,0.993-22.76,10.396c-2.872-0.793-7.736-1.19-12.597-1.19s-9.723,0.396-12.598,1.189C19.699,7.993,10.645,7,10.645,7
		c-3.948,9.931-3.913,20.306-3.172,22.132C2.834,34.169,0,40.218,0,48.483c0,35.932,29.809,36.508,37.334,36.508
		c1.703,0,5.088,0.004,8.666,0.009c3.578-0.005,6.965-0.009,8.666-0.009C62.191,84.991,92,84.415,92,48.483
		C92,40.218,89.166,34.169,84.527,29.132z M46.141,80.574H45.86c-18.859,0-33.545-2.252-33.545-20.58
		c0-4.389,1.549-8.465,5.229-11.847c6.141-5.636,16.527-2.651,28.316-2.651c0.045,0,0.093-0.001,0.141-0.003
		c0.049,0.002,0.096,0.003,0.141,0.003c11.789,0,22.178-2.984,28.316,2.651c3.68,3.382,5.229,7.458,5.229,11.847
		C79.686,78.322,65,80.574,46.141,80.574z M30.104,52.548c-3.588,0-6.498,4.026-6.498,8.996c0,4.971,2.91,8.999,6.498,8.999
		c3.592,0,6.502-4.028,6.502-8.999C36.605,56.574,33.695,52.548,30.104,52.548z"
      />
    </symbol>
    <symbol id="svg--pinterest" viewBox="-180 -180 850 850">
      <path
        d="M430.149,135.248C416.865,39.125,321.076-9.818,218.873,1.642
				C138.071,10.701,57.512,76.03,54.168,169.447c-2.037,57.029,14.136,99.801,68.399,111.84
				c23.499-41.586-7.569-50.676-12.433-80.802C90.222,77.367,252.16-6.718,336.975,79.313c58.732,59.583,20.033,242.77-74.57,223.71
				c-90.621-18.179,44.383-164.005-27.937-192.611c-58.793-23.286-90.013,71.135-62.137,118.072
				c-16.355,80.711-51.557,156.709-37.3,257.909c46.207-33.561,61.802-97.734,74.57-164.704
				c23.225,14.136,35.659,28.758,65.268,31.038C384.064,361.207,445.136,243.713,430.149,135.248z"
      />
    </symbol>
    <symbol id="svg--youtube" viewBox="-150 -150 800 800">
      <path
        d="M459,61.2C443.7,56.1,349.35,51,255,51c-94.35,0-188.7,5.1-204,10.2C10.2,73.95,0,163.2,0,255s10.2,181.05,51,193.8
			C66.3,453.9,160.65,459,255,459c94.35,0,188.7-5.1,204-10.2c40.8-12.75,51-102,51-193.8S499.8,73.95,459,61.2z M204,369.75v-229.5
			L357,255L204,369.75z"
      />
    </symbol>
  </svg>
  <div className="wrapper">
    <div className="connect">
      <a href="https://www.instagram.com/the_physiora?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" className="share twitter">
      <i className="fab fa-instagram" style={{width:"30px",height:"10px",fontWeight:"bold",fontSize:"30px",marginTop:"7px"}}></i>

        <span className="clip">TWITTER</span>
      </a>
      <a href="https://www.google.com/maps/place/THE+PHYSIORA+Physiotherapy+Clinic/@12.8286787,80.22752,17z/data=!3m1!4b1!4m6!3m5!1s0x3a525bbec19d78c9:0xefb6287fa6816325!8m2!3d12.8286787!4d80.2300949!16s%2Fg%2F11vbzyj7yy?entry=ttu" rel="author" className="share google">
        <svg role="presentation" className="svg--icon">
          <use xlinkHref="#svg--google" />
        </svg>
        <span className="clip">GOOGLE +</span>
      </a>
      <a href="https://www.facebook.com/the.physiora" rel="author" className="share facebook">
        <svg role="presentation" className="svg--icon">
          <use xlinkHref="#svg--facebook" />
        </svg>
        <span className="clip">FACEBOOK</span>
      </a>
    
      <a href="https://www.youtube.com/@THEPHYSIORA-so8fb" className="share  youtube">
        <svg role="presentation" className="svg--icon">
          <use xlinkHref="#svg--youtube" />
        </svg>
        <span className="clip">YOU-TUBE</span>
      </a>
    </div>
  </div>
          </div>
        </div>
                <div class="col-md-6 col-lg-6 col-xl-3">
            <div class="footer-item d-flex flex-column">
                <h5 class="mb-4 text-white">Quick Links</h5>
                <a href="/" class="footer-link">
                    <i class="fas fa-angle-right me-2"></i> Home
                </a>
                <a href="/about" class="footer-link">
                    <i class="fas fa-angle-right me-2"></i> About
                </a>
                <a href="/contact" class="footer-link">
                    <i class="fas fa-angle-right me-2"></i> Contact
                </a>
                <a href="/testimonial" class="footer-link">
                    <i class="fas fa-angle-right me-2"></i> Testimonial
                </a>
                <a href="/profile" class="footer-link">
                    <i class="fas fa-angle-right me-2"></i> Profile
                </a>
              
            </div>
        </div>

        <div className="col-md-6 col-lg-6 col-xl-3">
          <div className="footer-item d-flex flex-column">
            <h4 className="mb-4 text-white">Services</h4>
            <div style={{paddingRight:"100px"}}>
            <a href="/specialization" class="footer-link">
              <i className="fas fa-angle-right me-2" /> Specialization
            </a>
            <a href="/treatment" class="footer-link">
              <i className="fas fa-angle-right me-2" /> Treatment
            </a>
            <a href="/fitforfifty" class="footer-link">
              <i className="fas fa-angle-right me-2" /> FitOverFifty
            </a>
            <a href="/corporatecorrection" class="footer-link">
              <i className="fas fa-angle-right me-2" />CorporateCorrection
            </a>
          </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-3">
          <div className="footer-item d-flex flex-column">
            <h4 className="mb-4 text-white">Address</h4>
            <div className='footeradr'>
            <a  class="footer-link">
              <i className="fa fa-map-marker-alt me-2" />OMR,Egattur
             
            </a>
            <a class="footer-link">
              <i className="fas fa-envelope me-2" /> thephysioraclinic@gmail.com
            </a>
            {/* <a href="">
              <i className="fas fa-envelope me-2" /> https://www.datadna.in/
            </a> */}
            <a  class="footer-link">
              <i className="fas fa-phone me-2" /> +91 89254 49512
            </a>
            {/* <a href="" className="mb-3">
              <i className="fas fa-print me-2" /> +012 345 67890
            </a> */}
            </div>
          </div>
        </div>
        <p style={{color:"black",fontSize:"15px"}}>Designed and Developed by 
        <a href="https://www.datadna.in/" target="_blank" rel="noopener noreferrer" style={{ color: "#023E8A", marginLeft: '5px' }}>
              DATADNA
        </a></p>
      </div>
    </div>
    <div className="footer-icons-wrapper">
          <FloatingIcons isFooter={true} />
        </div>
  </div>
  );
};

export default Footer;
